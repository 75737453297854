<template>
  <div>
    <!-- because of page transition only one root element is allowed in this case -->
    <Component
      :is="`blueprint-${page?.entry?.blueprint?.replace('_', '-')}`"
      v-if="page && page?.entry"
      :class="colorTheme"
      :page="page?.entry"
    />

    <ClientOnly>
      <CookieBanner />
    </ClientOnly>
  </div>
</template>

<script setup>
import { store } from '/store.js'
const route = useRoute()

const { data: page } = await useAsyncGql({
  operation: 'Entry',
  variables: {
    uri: route.path,
  },
})

if (!page?.value?.entry) {
  throw createError({
    fatal: true,
    statusCode: 404,
  })
}

store.pageId = page?.value?.entry?.id

// META TAGS
await useHead({
  htmlAttrs: { lang: 'de' },
  title: getPageTitle(page.value.entry),
  meta: generateMeta(page.value.entry),
  link: generateLinks(page.value.entry),
})

// PAGE COLOR THEME
const colorTheme = computed(() => {
  const color = 'blue'

  if (!page?.value?.entry) {
    return color
  }

  // IF COLOR THEME IS SET ON PAGE
  if (page.value.entry.color_theme?.value) {
    return page.value.entry.color_theme.value
  }

  const parent = page.value.entry.parent

  // IF TWO PARENTS
  if (parent.parent?.color_theme?.value && parent.parent.url !== '/') {
    return parent.parent.color_theme.value
  }

  // IF ONE PARENT
  if (parent.color_theme?.value) {
    return parent.color_theme.value
  }

  return color
})
</script>
